import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { RichText } from "prismic-reactjs";
import { isMobile } from "../utils";
import Layout from "../components/Layout";
import TextInputField from "../components/TextInputField";
import { client as prismicClient } from "../libs/prismic";
import { getProfile } from "../utils/auth";
import Loader from "../components/Loader";
import { isRequired, isValidEmail, isText } from "../utils/validationFunc";
import { useForm } from "../hooks/useForm";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { navigate } from "gatsby";
import EmailService from "../services/EmailService";
import {
  actionTypes,
} from "../store/Dialog/type";
import "../styles/pages/invite.scss";

const emailService = new EmailService();

const Invite = () => {
  const dispatch = useDispatch();
  const [inviteDoc, setPortfolioDoc] = useState();
  const [showError, setShowError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("invite_a_friend");
      setPortfolioDoc(response);
    }
    fetchData();
  }, []);

  const user = getProfile();

  const onSubmit = async (formValues) => {
    try {
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { loading: true } });
      let data = {
        ...formValues,
        senderName: user.name
      }
      const _documentData = await emailService.sendInvite(data);
    } catch (err) {
      
    } finally {
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { loading: false } });
    }
  };

  const opalvestHandler = () => {
    navigate('https://www.opalvest.com')
  }
  const validations = [
    ({ recipientName }) =>
      isRequired(recipientName) || {
        recipientName: "This field is required",
      },
    ({ recipientName }) =>
      isText(recipientName) || {
        recipientName: "Field can only contain letters A - Z, numbers 0 - 9, ampersand (&) and hyphen (-).",
      },
    ({ recipientEmail }) =>
      isRequired(recipientEmail) || {
        recipientEmail: "This field is required",
      },
    ({ recipientEmail }) =>
      isValidEmail(recipientEmail) || {
        recipientEmail: "This field is email format",
      },
  ];

  const { inputValues, changeHandler, errors, isValid, submitHandler } =
    useForm(
      {
        recipientName: "",
        recipientEmail: "",
        personalNote: "",
      },
      validations,
      onSubmit
    );

  return inviteDoc ? (
    <Layout>
      <div style={{ minHeight: "calc(100vh - 400px)" }}>
        <Row className="justify-content-center mx-auto mw-1440 invite">
          <Col md={6} className="pr-20">
            <div className="banner1 rich-text">
              <RichText render={inviteDoc.data?.banner} />
            </div>
            <div className="from-name">
              From <span className="text-gray">{user.given_name}</span>
            </div>
            <div className="d-flex flex-column">
              <div>
                <TextInputField
                  label="Recipient Name"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderColor:
                        showError && errors.recipientName && "#C75E5A",
                    },
                  }}
                  onChange={changeHandler}
                  name="recipientName"
                  value={inputValues.recipientName}
                  style={{ marginTop: 10, width: "100%" }}
                  InputLabelProps={{
                    style: {
                      color:
                        showError && errors.recipientName
                          ? "#C75E5A"
                          : "rgba(0, 0, 0, 0.5)",
                    },
                  }}
                />
                {showError && errors.recipientName && (
                  <p className="error-message">{errors.recipientName}</p>
                )}
              </div>
              <div>
                <TextInputField
                  label="Recipient Email"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderColor:
                        showError && errors.recipientEmail && "#C75E5A",
                    },
                  }}
                  onChange={changeHandler}
                  name="recipientEmail"
                  value={inputValues.recipientEmail}
                  style={{ marginTop: 10, width: "100%" }}
                  InputLabelProps={{
                    style: {
                      color:
                        showError && errors.recipientEmail
                          ? "#C75E5A"
                          : "rgba(0, 0, 0, 0.5)",
                    },
                  }}
                />
                {showError && errors.recipientEmail && (
                  <p className="error-message">{errors.recipientEmail}</p>
                )}
              </div>
              <div>
                <TextInputField
                  label="Personal Note (optional)"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderColor:
                        showError && errors.personalNote && "#C75E5A",
                    },
                  }}
                  variant="filled"
                  multiline
                  rows={isMobile() ? 3 : 5}
                  onChange={changeHandler}
                  name="personalNote"
                  value={inputValues.personalNote}
                  style={{ marginTop: 10, width: "100%" }}
                  InputLabelProps={{
                    style: {
                      color:
                        showError && errors.personalNote
                          ? "#C75E5A"
                          : "rgba(0, 0, 0, 0.5)",
                    },
                  }}
                />
              </div>
            </div>
          </Col>

          <Col className="pl-20" md={6}>
            <div className="following-message">
              <b>The following message will be sent:</b>
            </div>
            <div className="preview-message">
              <div className="preview-note">
                {isEmpty(inputValues.personalNote) ? (
                  <span className="text-gray">[Personal Note]</span>
                ) : (
                  inputValues.personalNote.split("\n").map((el) => (
                    <>
                      <span>{el}</span>
                      <br />
                    </>
                  ))
                )}
              </div>
              <hr />
              <div>
                Hi <span className={inputValues.recipientName !== '' ? "" : "text-gray"}>{inputValues.recipientName !== '' ? `${inputValues.recipientName}` : '[Recipient Name]'},</span>
              </div>
              <p />
              {user.name} wants you to know about Opalvest, where you can
              trade exclusive Private Equity, Hedge and VC fund Opals.
              <p />
              <span className="clickable" onClick={opalvestHandler}>
                <b>Qualify in just a few minutes</b>
              </span>
            </div>
          </Col>
          <Col md={12} className="terms-container">
            <div className="terms-checkbox">
              <Checkbox
                onChange={(event) => setTermsChecked(event.target.checked)}
                sx={{ "&.MuiCheckbox-root": { padding: 0 } }}
              />
              <div
                className="term-privacy"
                style={{
                  borderColor: showError && !termsChecked && "#C75E5A",
                }}
              >
                I agree to the{" "}
                <a href="/terms-of-use">
                  {" "}
                  Opalvest Referral Program Terms of Use.
                </a>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="invite-btn-container">
              <Button
                disabled={!termsChecked}
                onClick={() => {
                  if (!isValid) {
                    setShowError(true);
                  } else {
                    submitHandler();
                  }
                }}
              >
                Send Invite
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  ) : (
    <Loader />
  );
};

export default Invite;
